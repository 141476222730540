import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

export default (props) => {
  const { data, price } = props;
  const { t } = useTranslation();

  const ContactCard = (props) => {
    const { data } = props;

    return (
      <div className="row justify-content-center">
        <div className="col-md-8 card-deck pt-5">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <div className="row mx-auto text-center">
                    <a
                      className="mx-auto my-3"
                      href={data.link}
                      style={{
                        color: "#3b3a3b",
                        fontSize: 16,
                        fontFamily: "lato",
                      }}
                    >
                      {t(data.text1)}
                      <br />
                      <img src={data.icon} style={{ width: 15, height: 15 }} />
                      <strong className="strong3">{t(data.text2)}</strong>
                    </a>
                  </div>
                  {!!data.text3 && !!data.text4 && (
                    <div className="row mx-auto text-center">
                      <a
                        className="mx-auto my-3"
                        href={data.link2}
                        style={{
                          color: "#3b3a3b",
                          fontSize: 16,
                          fontFamily: "lato",
                        }}
                      >
                        {t(data.text3)}
                        <br />
                        <img
                          src={data.icon2}
                          style={{ width: 15, height: 15 }}
                        />
                        <strong className="strong3">{t(data.text4)}</strong>
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <section id="prices" style={{ padding: "0px 0" }}>
      <div className="container-fluid wave-bg" style={{ paddingTop: 50 }}>
        <div className="container py-5 mt-0">
          <div
            className="row justify-content-center"
            style={{ marginTop: -50 }}
          >
            <div className="col-md-8">
              <div
                className="mx-auto text-center mb-5"
                style={{
                  position: "relative",
                  paddingBottom: "56.25%",
                  paddingTop: 25,
                  height: 0,
                }}
              >
                <iframe
                  title="Disinfection2U on Channel News Asia, 13 April 2020"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  src="https://www.youtube.com/embed/wNf2aj7o_PM"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-md-12 d-flex flex-column justify-content-center text-center pr-3">
              <h1 className="subheadline1 my-3 mb-5">Prices and Options</h1>
              <div className="row justify-content-between">
                <div className="col-md-10 justify-content-between mx-auto">
                  <p className="subheadline2 text-center">{price}</p>
                </div>
              </div>
            </div>
          </div> */}

          <div className="text-center mt-5">
            <h1 className="subheadline3">{t("ADVANCED.contactForQuote")}</h1>
            <a href="#contact" className="btn btn-primary btn-lg my-3 CTA">
              {t("ADVANCED.contactNow")}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
