import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

export default (props) => {
  const { contact = false } = props;
  const { t } = useTranslation();

  return (
    <section id="why" style={{ padding: "0px 0" }}>
      <div className="rings-bg">
        <div
          className="container"
          style={{ paddingTop: 50, paddingBottom: 50 }}
        >
          <div className="row mb-3">
            <div className="col-md-12 px-4 py-4 mb-2 text-center">
              <h1 className="subheadline2">{t("ADVANCED.howTitle")}</h1>
            </div>
          </div>
          <div className="row card-deck">
            <div className="card">
              <div className="card-body p-5">
                <div className="col-md-12">
                  <p className="smallerSubtitle mb-5">{t("ADVANCED.howDescription")}</p>
                  {/* // infographic */}
                  <div className="row justify-content-between">
                    <div className="col-md-3 text-center">
                      <div>
                        <img
                          src={require("../../../images/advanced-how/Diagram-1.png")}
                          style={{ maxHeight: 200, width: "auto" }}
                        />
                      </div>
                      <p className="text-center my-3 smallerSubtitle" style={{fontWeight: 550}}>
                        {t("ADVANCED.howStep1")}
                      </p>
                    </div>
                    <div className="col-md-3 text-center">
                      <div>
                        <img
                          src={require("../../../images/advanced-how/Diagram-2.png")}
                          style={{ maxHeight: 200, width: "auto" }}
                        />
                      </div>
                      <p className="text-center my-3 smallerSubtitle" style={{fontWeight: 550}}>
                        {t("ADVANCED.howStep2")}
                      </p>
                    </div>
                    <div className="col-md-3 text-center">
                      <div>
                        <img
                          src={require("../../../images/advanced-how/Diagram-3.png")}
                          style={{ maxHeight: 200, width: "auto" }}
                        />
                      </div>
                      <p className="text-center my-3 smallerSubtitle" style={{fontWeight: 550}}>
                        {t("ADVANCED.howStep3")}
                      </p>
                    </div>
                    <div className="col-md-3 text-center">
                      <div>
                        <img
                          src={require("../../../images/advanced-how/Diagram-4.png")}
                          style={{ maxHeight: 200, width: "auto" }}
                        />
                      </div>
                      <p className="text-center my-3 smallerSubtitle" style={{fontWeight: 550}}>
                        {t("ADVANCED.howStep4")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {contact && (
            <div className="text-center mt-5">
              <h1 className="subheadline3">{t("ADVANCED.contactForQuote")}</h1>
              <a href="#contact" className="btn btn-primary btn-lg my-3 CTA">
                {t("ADVANCED.contactNow")}
              </a>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
