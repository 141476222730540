export default {
  title: "ADVANCED.processSectionTitle",
  items: [
    {
      image: require("../../images/new/icon-1-yellow.png"),
      text: "ADVANCED.processStep1",
    },
    {
      image: require("../../images/new/icon-2-yellow.png"),
      text: "ADVANCED.processStep2",
    },
    {
      image: require("../../images/new/icon-3-yellow.png"),
      text: "ADVANCED.processStep3",
    },
    {
      image: require("../../images/new/icon-4-yellow.png"),
      text: "ADVANCED.processStep4",
    },
    {
      image: require("../../images/new/icon-5-yellow.png"),
      text: "ADVANCED.processStep5",
    },
  ],
};
