import React from "react";
// Common components
import PageContainer from "../components/PageContainer";
import SEO from "../components/seo";
import BenefitSection from "../components/Sections/BenefitSection";
import ClientsSections from "../components/Sections/ClientsSection";
import ComplianceSection from "../components/Sections/ComplianceSection";
import ContactSection from "../components/Sections/ContactSection";
import FaqSection from "../components/Sections/FaqSection";
import FeaturedBySection from "../components/Sections/FeaturedBySection";
import IntroSection from "../components/Sections/IntroSection";
import SeoTextSection from "../components/Sections/SeoTextSection";
import ThankYouModal from "../components/ThankYouModal";
import DisinfectionGallerySection from "../components/Sections/DisinfectionGallerySection";
import AdvancedPriceSection from "../components/Sections/AdvancedPriceSection";
import AdvancedProcessSection from "../components/Sections/AdvancedProcessSection";
import AdvancedHowSection from "../components/Sections/AdvancedHowSection";
import GetQuoteModal from "../components/GetQuoteModal";
//* Constants*/
import { AdvancedCoating as AdvancedFaq } from "../constants/FaqDetails";
import ProcessDetails from "../constants/ProcessDetails/AdvancedCoating";
import { AdvancedCoating as AdvancedBenefit } from "../constants/BenefitDetails";
// Custom components
import CallBackForm from "../components/CallBackForm";
import { useTranslation } from "gatsby-plugin-react-i18next";

const Advanced = () => {
  const { t } = useTranslation();
  const sgFlag = require("../images/singapore.svg");
  const bgVideo = require("../assets/videos/advanced-coating.mp4");
  const AdvancedCallbackForm = () => <CallBackForm leads="advanced-coating" />;

  return (
    <PageContainer>
      <SEO title={t("ADVANCED.seoTitle")} />
      <div>
        {/* <!-- Compliance banner --> */}
        <ComplianceSection
          image={sgFlag}
          imageAlt="Malaysia Flag"
          title={t("ADVANCED.complianceBannerTitle")}
        />
        {/* <!-- Intro --> */}
        <IntroSection
          video={bgVideo}
          topSubtitle={t("ADVANCED.introTopSubtitleAdvanced")}
          title={t("ADVANCED.introTitleAdvanced")}
          body={t("ADVANCED.introContentAdvanced")}
          bodyTagline={t("ADVANCED.introTagLineAdvanced")}
          buttonText={t("ADVANCED.introCTAButtonAdvanced")}
          form={AdvancedCallbackForm}
        />
        {/* <!-- Clients we served --> */}
        <ClientsSections />
        {/* <!-- Featured by Companies --> */}
        <FeaturedBySection />
        {/* <!-- PRICES AND OPTIONS (TABLE) --> */}
        <AdvancedPriceSection />
        {/* price="Treatments starting from SG$5 psf of
          treatment surface. Price includes fully qualified
          application team, and requisite site pre-treatments. High
          volume discounts available."
        */}
        <AdvancedProcessSection data={ProcessDetails} />
        <AdvancedHowSection />
        {/* <!-- WHY GET DISINFECTION (highlights) --> */}
        {/* <DisinfectionGallerySection commercial /> */}
        {/* <!-- DISINFECTION PROCESS --> */}
        {/* <!-- WHO WOULD BENEFIT? --> */}
        <BenefitSection data={AdvancedBenefit} />
        {/* <!-- FAQ --> */}
        <FaqSection spec data={AdvancedFaq} />
        {/* <!-- CTA --> */}
        <ContactSection form={AdvancedCallbackForm} />
        {/* <!-- SEO --> */}
        <SeoTextSection />
        <GetQuoteModal />
        <ThankYouModal />
      </div>
    </PageContainer>
  );
};

export default Advanced;
