import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

export default (props) => {
  const { data } = props;
  const { t } = useTranslation();

  return (
    <section id="process" style={{ padding: "0px 0" }}>
      <div className="container" style={{ paddingTop: 50, paddingBottom: 50 }}>
        <div className="container mt-0" style={{ marginTop: 10 }}>
          <div className="row">
            <div className="col-md-12 d-flex flex-column justify-content-center text-center pr-3">
              <h1 className="subheadline2 my-3 mb-5">
                {t(data.title)}
              </h1>
              <div className="row justify-content-between text-center">
                {data &&
                  data.items.map((item, key) => (
                    <div key={key} className="col-md-2 px-3 text-center">
                      <div className="mt-3">
                        <img
                          src={item.image}
                          style={{ width: 120, maxHeight: 227 }}
                        />
                      </div>
                      <p className="subheadline4 py-2" style={{ fontSize: 16 }}>
                        {t(item.text)}
                      </p>
                      {item && !!item.subText && (
                        <p className="smallerSubtitle py-2" style={{ fontSize: 16 }}>
                          {t(item.subText)}
                        </p>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
          {!!data.advancedTreatment && (
            <div className="row py-5">
              <div className="col-md-8 mx-auto">
                <p className="subheadline4 text-center">
                  {t(data.advancedTreatment)}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
